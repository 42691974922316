import React from 'react'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'

const AppLayout = ({ children, breadcrumbs = [], fluid = false }) => (
  <>
    <Navbar bg='primary' variant='dark'>
      <Link to='/'>
        <img src='https://static.da-io.net/images/da-logo.png' alt='DA logo' height='38' />
      </Link>
      <LinkContainer to='/'>
        <Navbar.Brand>
          Constituency Demarcation
        </Navbar.Brand>
      </LinkContainer>
      <Nav>
        {breadcrumbs.map(({ to, name }) => <LinkContainer key={to} to={to}><Nav.Link>{name}</Nav.Link></LinkContainer>)}
      </Nav>
    </Navbar>
    <Container className='mt-2' fluid={fluid}>
      {children}
    </Container>
  </>
)

export default AppLayout
