import React from 'react'

import AppLayout from './AppLayout'
import { ErrorMessage } from './ErrorMessages'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError (error) {
    return { error }
  }

  render () {
    if (this.state.error) {
      return <AppLayout><ErrorMessage message={this.state.error.message} /></AppLayout>
    }

    return this.props.children
  }
}

export default ErrorBoundary
