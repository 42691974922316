import React, { Suspense, lazy } from 'react'
import { AuthContext, useAuthentication, getLocalToken } from 'react-u5auth'

import { ApolloClient, ApolloProvider, ApolloLink, HttpLink, InMemoryCache, defaultDataIdFromObject } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import AppLayout from './components/AppLayout'
import LoadingSpinner from './components/LoadingSpinner'
import ErrorBoundary from './components/ErrorBoundary'
import './App.css'

const IndexContainer = lazy(() => import('./components/IndexContainer'))
const ProvinceContainer = lazy(() => import('./components/ProvinceContainer'))
const MuniContainer = lazy(() => import('./components/MuniContainer'))
const ProposalContainer = lazy(() => import('./components/ProposalContainer'))
const CompareContainer = lazy(() => import('./components/CompareContainer'))

const clientId = process.env.REACT_APP_AUTH_CLIENT_ID || '65269383002585698f8e300d35f4'
const authProvider = process.env.REACT_APP_AUTH_PROVIDER || 'https://login.voteda.org'

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        )
      }
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    setContext((_, { headers }) => {
      const token = getLocalToken()
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : ''
        }
      }
    }),
    new HttpLink({
      uri: process.env.REACT_APP_API_URL,
      credentials: 'same-origin'
    })
  ]),
  cache: new InMemoryCache({
    dataIdFromObject: object => {
      switch (object.__typename) {
        case 'Province':
        case 'Municipality':
        case 'VD':
          return `${object.__typename}:${object.code}`
        default:
          return defaultDataIdFromObject(object)
      }
    }
  })
})

const AuthenticatedApp = () => {
  const { authenticated } = useAuthentication()

  if (!authenticated) {
    return <AppLayout><LoadingSpinner /> Logging in...</AppLayout>
  }

  return (
    <Switch>
      <Route path='/' exact><IndexContainer /></Route>
      <Route path='/province/:code'><ProvinceContainer /></Route>
      <Route path='/municipality/:code'><MuniContainer /></Route>
      <Route path='/proposal/:id'><ProposalContainer /></Route>
      <Route path='/compare/:oldId/:newId'><CompareContainer /></Route>
    </Switch>
  )
}

const App = () => (
  <AuthContext clientId={clientId} provider={authProvider}>
    <ApolloProvider client={apolloClient}>
      <Router>
        <ErrorBoundary>
          <Suspense fallback={<AppLayout><LoadingSpinner /></AppLayout>}>
            <AuthenticatedApp />
          </Suspense>
        </ErrorBoundary>
      </Router>
    </ApolloProvider>
  </AuthContext>
)

export default App
